<template>
  <div>
    <div class="wrap">
      <div class="menus">
        <div class="menu" :class="{ active: isActive == 'task-template' }" @click="handleSelect('task-template')">
          <div class="num">36</div>
          <div class="tip">任务模板</div>
        </div>
        <div class="menu" :class="{ active: isActive == 'org-list' }" @click="handleSelect('org-list')">
          <div class="num">50</div>
          <div class="tip">子系统</div>
        </div>
        <div class="menu" :class="{ active: isActive == 'setting' }" @click="handleSelect('setting')">
          <img src="@/assets/icons/func.png" alt="" class="icon">
          <div class="tip">系统管理</div>
        </div>
        <div class="menu" :class="{ active: isActive == 'base-data' }" @click="handleSelect('base-data')">
          <img src="@/assets/icons/setting.png" alt="" class="icon">
          <div class="tip">参数配置</div>
        </div>
      </div>

    </div>
    <div class="main">
      <router-view />
    </div>
  </div>
</template>
<script>

export default {
  components: {},
  data() {
    return {
      isActive: '',
    }
  },
  methods: {
    handleSelect(key) {
      this.isActive = key;
      // this.$router.push('/platform/home/' + key)
    }
  }
}
</script>
<style lang="less" scoped>
.wrap {
  padding: 24px 0;
  overflow: hidden;
  .menus {
    // padding:24px 0 ;
    width: 1400px;
    margin:auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    // padding: 0 10px;

    .menu {
      width: 100px;
      margin-right:20px;
      // margin: 0 10px;
      height: 100px;
      border-radius: 10px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
      overflow: hidden;
      position: relative;
      box-shadow: 0 0 16px rgba(51, 135, 255, .12);

      &.active {
        &::after {
          display: block;
          content: ' ';
          width: 100%;
          height: 10px;
          position: absolute;
          bottom: 0;
          left: 0;
          background: @primary-color;
        }

        .tip {
          color: @primary-color;
        }
      }

      .num {
        font-size: 40px;
        color: @primary-color;
        height: 40px;
        line-height: 40px;
      }

      .icon {
        // width:40px;
        height: 38px;
        // object-fit: cover;
      }

      .tip {
        margin-top: 6px;
        color: @text-color;
        font-size: 18px;
        font-weight: 100;
      }
    }
  }
  
}
.main {
    width:1400px;
    margin:auto;
  }
</style>